.el-select {
  width: 100%;
}

#check-range-selecter .el-input-group__prepend {
  border-right: 1px solid #bfcbd9;
  z-index: 2;
}

#check-range-selecter .el-input__inner {
  border-radius: 0 4px 4px 0;
  border-left: 0;
  z-index: 1;
}
